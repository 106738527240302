import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { isAndroid, isIOS, isMacOs, isMobile, isMobileOnly, isWindows, isWinPhone } from "react-device-detect";

export const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

const getDeviceType = () => {
    if (isWindows) return '01';

    if (isMacOs) return '02';

    if (isAndroid || isWinPhone || isIOS || isMobileOnly || isMobile) return '03';

    return '04'
};

const ptype = getDeviceType();

export const tags = {
    userData: 'userData',
    topVideo: 'topVideo',
    newVideo: 'newVideo',
    searchVideo: 'searchVideo',
    mainPage: 'mainPage',
    category: 'category',
    organization: 'organization',
    notifications: 'notifications'
};

export const methods = {
    post: 'POST',
    get: 'GET',
    put: 'PUT',
    delete: 'DELETE'
}

export const toFormData = (data: Object) => {
    const body = new FormData();

    for (const [ key, value ] of Object.entries(data)) {
        body.append(key, value);
    }

    return body;
}

const baseQuery = fetchBaseQuery({ 
    baseUrl,
    mode: 'cors',
    credentials: 'include'
});

const baseQueryWithDeviceType: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, appApi, extraOptions) => {
    const currentArgs = args as FetchArgs;

    currentArgs.params = {
        ...currentArgs.params,
        ptype
    };

    let result = await baseQuery(currentArgs, appApi, extraOptions);

    return result;
}

export const api = createApi({
    baseQuery: baseQueryWithDeviceType,
    reducerPath: 'api',
    tagTypes: Object.values(tags),
    endpoints: () => ({})
});