import { useCallback } from "react";
import storage from "../constants/storage";
import { useUserAuth } from "./useUserAuth";

const timeout = parseInt(process.env.REACT_APP_LOGIN_TIMEOUT ?? '1800') * 1000;

export default function useActivity() {
    const user = useUserAuth();

    const checkAndSet = useCallback(() => {
        const timestamp = localStorage.getItem(storage.lastActivity);

        if (!user.isLoggedIn) {
            localStorage.removeItem(storage.lastActivity);
            return;
        }

        if (timestamp) {
            const lastActivityTS = Date.now() - parseInt(timestamp);
            
            if (lastActivityTS >= timeout) return;
        }

        localStorage.setItem(storage.lastActivity, `${Date.now()}`);
    }, [ user.isLoggedIn ]);

    const check = useCallback(() => {
        const timestamp = localStorage.getItem(storage.lastActivity);

        if (!timestamp || (Date.now() - parseInt(timestamp))>= timeout) return false;

        return true;
    }, [])

    return {
        checkAndSet,
        check
    };
}