import "./styles/global.scss";
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/600-italic.css"
import "@fontsource/montserrat/700.css"
import { Outlet } from "react-router-dom";
import { Suspense, useEffect } from "react";
import { Throbber } from "./components/ui/informers/Throbber";
import useActivity from "./hooks/useActivity";
import { useLazyLogoutQuery } from "./app/endpoints/authApi";
import { useUserAuth } from "./hooks/useUserAuth";

function App() {
    const { checkAndSet, check } = useActivity();
    const [ logout ] = useLazyLogoutQuery();
    const { isLoggedIn } = useUserAuth();
    checkAndSet();

    useEffect(() => {
        const tickerHandler = () => !check() && isLoggedIn && logout(undefined);
        const interval = setInterval(tickerHandler, 1000);
        tickerHandler();

        return () => clearInterval(interval);
    }, [ check, logout, isLoggedIn ]);

    return (
        <Suspense fallback={<Throbber fullScreen/>}>
            <Outlet/>
        </Suspense>
    );
}

export default App;
